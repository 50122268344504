import React from "react";
import { Card, CardHeader, CardBody } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useTranslation } from "react-i18next";
import { useLazyGetProductsTableQuery } from "api/analyticsSlice";
import { useGetProductsTableQuery } from "api/analyticsSlice";

export const SortableTable = ({ keyname, title }) => {
  const { t } = useTranslation();
  const [products] = useLazyGetProductsTableQuery();
  const [productsData, setProductsData] = React.useState({
    data: [],
    total: 0,
    page: 1,
    limit: 10,
    sortBy: "quantity_sum",
    sortDir: "desc",
  });
  const { data: initalData, isLoading } = useGetProductsTableQuery({
    keyname,
    reportPeriod: "custom",
    limit: 10,
    page: 1,
    sortBy: "quantity_sum",
    sortDir: "desc",
  });
  const COLUMNS = [
    {
      dataField: "name",
      text: keyname === "products_table" ? t("Product") : t("Category"),
      sort: false,
    },
    {
      dataField: "quantity_sum",
      text: t("Quantity"),
      sort: true,
    },
    {
      dataField: "revenue_sum",
      text: `${t("Revenue")} (€)`,
      sort: true,
      formatter: (cell) => {
        return cell?.toFixed(2);
      },
    },
  ];
  const componentRef = React.useRef(null);
  const onTableChange = async (type, { sortField, sortOrder, page, sizePerPage, data }) => {
    let sortDir = sortOrder === "asc" ? "asc" : "desc";
    let sortBy = sortField || "quantity_sum";
    let currPage = page < 1 ? 1 : page;
    const loadProducts = await products({
      keyname,
      sortBy,
      sortDir,
      page: currPage,
      limit: sizePerPage,
      reportPeriod: "custom",
    });
    setProductsData({
      ...loadProducts.data,
      total: parseInt(loadProducts.data.total),
      page: parseInt(loadProducts.data.page),
      limit: parseInt(loadProducts.data.limit),
    });
  };

  React.useEffect(() => {
    if (!isLoading && initalData) {
      setProductsData({
        ...initalData,
        total: parseInt(initalData.total),
        page: parseInt(initalData.page),
        limit: parseInt(initalData.limit),
      });
    }
  }, [initalData, isLoading]);

  return (
    <div>
      <Card className="bg-white">
        <CardHeader className="pb-0 border-0">
          <h3 className="mb-0 ">{title}</h3>
        </CardHeader>
        <CardBody className="bg-white table-responsive">
          {productsData && (
            <BootstrapTable
              ref={componentRef}
              className="bg-white"
              bootstrap4={true}
              id="react-bs-table"
              bordered={false}
              remote={{ sort: true, pagination: true }}
              keyField="name"
              data={productsData.data}
              columns={COLUMNS}
              pagination={paginationFactory({
                page: productsData.page,
                sizePerPage: productsData.limit,
                totalSize: productsData.total,
                pageStartIndex: 1,
                sizePerPageList: [
                  {
                    text: "10",
                    value: 10,
                  },
                  {
                    text: "20",
                    value: 20,
                  },
                  {
                    text: "50",
                    value: 50,
                  },
                  {
                    text: "All",
                    value: productsData.total,
                  },
                ],
                alwaysShowAllBtns: false,
                showTotal: false,
                withFirstAndLast: false,
                sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
                  <div className="dataTables_length pb-2" id="datatable-basic_length">
                    <label>
                      {t("Show_label")}
                      {
                        <select
                          name="datatable-basic_length"
                          aria-controls="datatable-basic"
                          className="form-control form-control-sm"
                          onChange={(e) => onSizePerPageChange(e.target.value)}
                        >
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value={productsData.total}>All</option>
                        </select>
                      }{" "}
                    </label>
                  </div>
                ),
              })}
              onTableChange={onTableChange}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
};
