import React, { useEffect, useReducer, useRef, useState } from "react";
import { useUpdateProductMutation, ProductService } from "api/productsSlice";
import { TagTypes } from "utilities/enums/TagTypes";
import { useDispatch, useSelector } from "react-redux";
import { useAddOrderMutation, useAddCustomerIfNotExistsMutation, apiOrders } from "api/ordersSlice";
import { useNavigate } from "react-router-dom";
import { selectCurrentUser } from "auth/authSlice";
import { broadCastOrdersChangeWS, subscribeToOrdersChangeWS } from "config/websocket";
import { clearCart, setDiscount } from "redux/cart/cartSlice";
import { useTranslation } from "react-i18next";
import { selectCurrentOrganisation } from "auth/authSlice";
import { generateUUID, logError } from "utilities/utils";
import { InvoiceType } from "utilities/enums/InvoiceType";
import { selectCurrentUserRules } from "auth/authSlice";
import ROUTES_OBJ from "utilities/enums/Routes";
import { useOrdersBulkUpdateMutation } from "api/ordersSlice";
import { PaymentMethods } from "utilities/enums/PaymentMethods";
import CartListItem from "./CartListItem";
import { toggleCartOpen } from "redux/cart/cartSlice";
import { TAKEAWAY_FUNNEL_NAME } from "utilities/constants";

const useCart = ({ cartItems, onCheckout }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [addOrder, { isLoading: addOrderLoading, isError: addOrderError }] = useAddOrderMutation();
  const [addCustomerIfNotExists, { isLoading: addCustomerLoading }] = useAddCustomerIfNotExistsMutation();
  const [updateProduct] = useUpdateProductMutation();
  const { productQuantities } = useSelector((state) => state.productQuantities);
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const { cartTable, discount, isOpen } = useSelector((state) => state.cart);
  const isTakeaway = cartTable?.attributes?.name === TAKEAWAY_FUNNEL_NAME;
  const { username: submittedBy } = useSelector(selectCurrentUser);
  const [paymentMethodDialogOpen, setPaymentMethodDialogOpen] = useState(false);
  const [paymentErrorMsg, setPaymentErrorMsg] = React.useState("");
  const [ordersBulkUpdate, { isLoading: isBulkUpdateLoading }] = useOrdersBulkUpdateMutation();
  const [notes, setNotes] = useState("");
  const user_rules = useSelector(selectCurrentUserRules);
  const discount_rule_enabled = user_rules.find(
    (rule) => rule.name === `Route:${ROUTES_OBJ.TABLE_ACTIONS_DISCOUNT.path}`
  )?.value;
  const {
    discounts_enabled,
    customers_per_order_enabled,
    cloud_cash_register_enabled,
    manual_cloud_cash_register_enabled,
    wholesale_enabled,
    external_source_types_enabled,
    default_option_without_invoice,
    auto_invoice_payment_card,
    pos_integration_enabled,
    available_external_sources,
  } = useSelector((state) => state.settings);
  const { t } = useTranslation();
  const [submitInvoiceType, setSubmitInvoiceType] = useState(() => getDefaultSubmitInvoiceType());
  const organisationId = useSelector(selectCurrentOrganisation);
  const [deliveryMethod, setDeliveryMethod] = useState("efood"); // Default to 'Efood'
  const [customerAccordionOpen, setCustomerAccordionOpen] = React.useState(false);
  const [newOrderCustomers, setNewOrderCustomers] = useState(0);
  const [editProductOpen, setEditProductOpen] = useState(false);
  const [selectedProductInfo, setSelectedProductInfo] = useState({
    id: -1,
    quantity: 1,
    variants: [],
    notes: "",
    category: "",
    vat_category: "",
    name: "",
  });

  const [customerInputs, setCustomerInputs] = useState({
    name: "",
    vat_number: "",
    doy: "",
    gemi_number: "",
    address: "",
    city: "",
    postcode: "",
    phone_number: "",
    errors: {},
    organisation: organisationId,
  });
  const [discountReason, setDiscountReason] = useState("");
  const [discountAccordionOpen, setDiscountAccordionOpen] = useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [calculateModalOpen, setCalculateModalOpen] = React.useState(false);
  const allow_discount = discounts_enabled && discount_rule_enabled;
  function getDefaultSubmitInvoiceType() {
    // Primer is disabled
    if (!cloud_cash_register_enabled) return "";

    // Primer is enabled but there's no option for without invoice -- takeaway doesn't have openTable type
    if (cloud_cash_register_enabled && !manual_cloud_cash_register_enabled && !isTakeaway) {
      return InvoiceType.OpenTable;
    }

    // Primer is enabled and there is option for without invoice
    if (cloud_cash_register_enabled && manual_cloud_cash_register_enabled) {
      if (default_option_without_invoice) {
        return "";
      } else {
        return InvoiceType.OpenTable;
      }
    }
  }

  function handleCustomerInputChange(event) {
    setCustomerInputs((prevInputs) => {
      return {
        ...prevInputs,
        [event.target.name]: event.target.value,
      };
    });
  }

  function onCartItemClick(productInfo) {
    setSelectedProductInfo(productInfo);
    setEditProductOpen(true);
  }

  function getForceSendCloudInvoiceTakeaway(paymentMethod) {
    return (
      cloud_cash_register_enabled &&
      (submitInvoiceType === InvoiceType.Retail ||
        submitInvoiceType === InvoiceType.Wholesale ||
        paymentMethod === PaymentMethods.CARD.value)
    );
  }

  function refreshComponent() {
    //Invalidates cache for refreshing the cart data
    dispatch(
      ProductService.util.invalidateTags([TagTypes.Products, TagTypes.Categories, TagTypes.Variants, TagTypes.Orders])
    );
    //Force updates the cart component when an orderhas been received from an external client.
    forceUpdate();
    cartQuantitiesValid();
  }
  useEffect(() => {
    subscribeToOrdersChangeWS(organisationId, refreshComponent);
  }, []);

  const cartItemsList = cartItems.map((cartItem, cartItemIndex) => {
    return <CartListItem cartItem={cartItem} key={cartItemIndex} onCartItemClick={onCartItemClick} />;
  });
  const [selectedOrderTotal, setSelectedOrderTotal] = useState(0);
  const [orderGrandTotal, setOrderGrandTotal] = useState(0);

  useEffect(() => {
    const total = cartItems.reduce((total, item) => {
      return total + item.price * item.quantity;
    }, 0);
    setSelectedOrderTotal(total);
    setOrderGrandTotal(total - discount);
  }, [cartItems, discount]);

  const getInvoiceType = () => {
    if (submitInvoiceType === "delivery") {
      return InvoiceType.Retail;
    }
    return submitInvoiceType?.length > 0 ? submitInvoiceType : InvoiceType.OpenTable;
  };

  /**
   * If the set invoice type from the user is empty and
   * the organisation is configured to have "send auto invoice on payment method card" and payment method card
   * we submit with invoice type "Retail" because we should submit invoice
   * @param {*} paymentMethod
   * @returns the invoiceType to send for the order(s) to be submitted
   */
  function getTakeawayPaymentInvoiceType(paymentMethod) {
    let finalInvoiceType = submitInvoiceType || InvoiceType.Retail;

    if (
      cloud_cash_register_enabled &&
      submitInvoiceType === InvoiceType.OpenTable &&
      paymentMethod === PaymentMethods.CARD.value
    ) {
      finalInvoiceType = InvoiceType.Retail;
    }

    return finalInvoiceType;
  }

  function getTakeawayNewOrderInvoiceType(paymentMethod) {
    let finalInvoiceType = submitInvoiceType || InvoiceType.Retail;

    if (
      cloud_cash_register_enabled &&
      paymentMethod === PaymentMethods.CARD.value &&
      submitInvoiceType !== InvoiceType.Retail &&
      submitInvoiceType !== InvoiceType.Wholesale
    ) {
      finalInvoiceType = InvoiceType.Retail;
    }

    return finalInvoiceType;
  }

  const submitTakeawayOrder = async (paymentMethod = null, terminalId = null, tipAmount = null) => {
    try {
      const UUID = generateUUID(submittedBy, organisationId);
      const addedCustomer = await handleAddCustomer();
      const customerId = addedCustomer?.data?.id;
      const newOrder = await addOrder({
        uuid: UUID,
        cartItems: cartItems,
        submittedBy: submittedBy,
        notes: notes,
        origin: "new",
        invoice_type: getTakeawayNewOrderInvoiceType(paymentMethod),
        numberOfCustomers: newOrderCustomers,
        force_send_cloud_invoice: false,
        external_source: "takeaway",
        discount: discount,
        discount_reason: discountReason,
        total: orderGrandTotal,
        ...(customerId && { customer: { id: customerId } }),
      });

      const uniqueOrderIds = [newOrder?.data?.data?.id];
      const UUIDPAY = generateUUID(submittedBy, organisationId);
      const data = {
        orders: uniqueOrderIds,
        paymentMethod: paymentMethod === null ? PaymentMethods.CASH.value : paymentMethod,
        resultStatus: "payed",
        notes: notes,
        submittedBy: submittedBy,
        discount: discount,
        discount_reason: discountReason,
        allowDiscount: allow_discount,
        force_send_cloud_invoice: getForceSendCloudInvoiceTakeaway(paymentMethod),
        organisationId: organisationId,
        invoice_type: getTakeawayPaymentInvoiceType(paymentMethod),
        customer: submitInvoiceType !== InvoiceType.Retail ? customerInputs : null,
        terminalId: terminalId,
        tipAmount: tipAmount,
        uuid: UUIDPAY,
      };

      const response = await ordersBulkUpdate(data);

      if (response?.data?.error) {
        setPaymentErrorMsg(response?.data?.msg);
        logError("bulkupdate error", {
          user: submittedBy,
          errorFunction: "bulkUpdate",
          pos_integration_enabled: pos_integration_enabled,
          msg: "Error returned from bulkUpdate, msg: " + response?.data?.msg,
          ...data,
        });
      } else {
        dispatch(clearCart());
        if (isOpen) {
          dispatch(toggleCartOpen());
        }
        broadCastOrdersChangeWS(organisationId);
        setPaymentMethodDialogOpen(false);
        setCustomerInputs({
          name: "",
          vat_number: "",
          doy: "",
          gemi_number: "",
          address: "",
          city: "",
          postcode: "",
          phone_number: "",
          errors: {},
          organisation: organisationId,
        });
        navigate("/admin/takeaway?success=true");
      }
    } catch (error) {
      console.error("Error in submitTakeawayOrder:", error);
    }
  };

  const submitOrder = () => {
    if (cartTable?.id === -1 && cartTable?.attributes?.name === "") {
      navigate("/admin/tables");
      return;
    }

    handleAddCustomer().then((addedCustomer) => {
      let customerId = addedCustomer?.data?.id;
      const UUID = generateUUID(submittedBy, organisationId);

      addOrder({
        uuid: UUID,
        tableId: cartTable?.id,
        cartItems: cartItems,
        submittedBy: submittedBy,
        notes: notes,
        origin: "new",
        invoice_type: getInvoiceType(),
        numberOfCustomers: newOrderCustomers,
        force_send_cloud_invoice: submitInvoiceType?.length > 0 || submitInvoiceType === "delivery",
        ...(submitInvoiceType === "delivery" && {
          external_source: deliveryMethod,
          discount: discount,
          discount_reason: discountReason,
          total: orderGrandTotal,
        }),
        ...(customerId && { customer: { id: customerId } }),
      }).then(() => {
        dispatch(apiOrders.util.invalidateTags([TagTypes.Orders]));
      });
    });

    navigate("/admin/tables");
    dispatch(clearCart());
  };

  /**
   * In case we submit a wholesale receipt, we should have a customer for it
   * Before submitting the order we should save (or retrieve if existing) the customer from our database
   * This function handles the retrieval of the customer in general or after they are added from the backend
   * @returns
   */
  async function handleAddCustomer() {
    if (submitInvoiceType === InvoiceType.Wholesale) {
      return await addCustomerIfNotExists({ entity_data: customerInputs });
    }
  }

  function customerFieldsValidator() {
    let isValid = true;
    if (customerInputs.name.length === 0) {
      setCustomerInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, name: t("Name_invalid_msg") },
        };
      });
      isValid = false;
    } else {
      setCustomerInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, name: "" },
        };
      });
    }
    if (customerInputs.vat_number.length === 0) {
      setCustomerInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, vat_number: t("Vat_number_invalid_msg") },
        };
      });
      isValid = false;
    } else {
      setCustomerInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, vat_number: "" },
        };
      });
    }

    if (customerInputs.doy.length === 0) {
      setCustomerInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, doy: t("Doy_invalid_msg") },
        };
      });
      isValid = false;
    } else {
      setCustomerInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, doy: "" },
        };
      });
    }

    if (customerInputs.address.length === 0) {
      setCustomerInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, address: t("Address_invalid_msg") },
        };
      });
      isValid = false;
    } else {
      setCustomerInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, address: "" },
        };
      });
    }

    if (customerInputs.city.length === 0) {
      setCustomerInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, city: t("City_invalid_msg") },
        };
      });
      isValid = false;
    } else {
      setCustomerInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, city: "" },
        };
      });
    }

    if (customerInputs.postcode.length === 0) {
      setCustomerInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, postcode: t("Postcode_invalid_msg") },
        };
      });
      isValid = false;
    } else {
      setCustomerInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, postcode: "" },
        };
      });
    }
    return isValid;
  }

  const handleTakeawaySubmitOrder = () => {
    setDeliveryMethod("takeaway");
    if (submitInvoiceType === InvoiceType.Wholesale && !customerFieldsValidator()) {
      return;
    } else {
      // add pending order
      setPaymentMethodDialogOpen(true);
    }
  };

  const handleTablesSubmitOrder = () => {
    if (submitInvoiceType === InvoiceType.Wholesale && !customerFieldsValidator()) {
      return;
    }
    submitOrder();
    if (onCheckout) {
      onCheckout();
    }
  };

  const handleSubmitOrder = async () => {
    if (isTakeaway) {
      handleTakeawaySubmitOrder();
    } else {
      handleTablesSubmitOrder();
    }
  };

  const cartQuantitiesValid = () => {
    // check if any cart item quantity is greater than the product quantity
    // if so, return true to disable the checkout button
    return cartItems.some((cartItem) => {
      const totalAlreadyOrdered = cartItems.reduce((total, item) => {
        return total + (item.id === cartItem.id ? item.quantity : 0);
      }, 0);
      const productQuantity = productQuantities?.find((item) => item.id === cartItem.id);
      return productQuantity && productQuantity.quantity < totalAlreadyOrdered;
    });
  };

  return {
    cartItemsList,
    selectedOrderTotal,
    orderGrandTotal,
    submitTakeawayOrder,
    handleSubmitOrder,
    isTakeaway,
    paymentMethodDialogOpen,
    setPaymentMethodDialogOpen,
    paymentErrorMsg,
    setNotes,
    notes,
    setSubmitInvoiceType,
    submitInvoiceType,
    setDeliveryMethod,
    deliveryMethod,
    customerAccordionOpen,
    setCustomerAccordionOpen,
    newOrderCustomers,
    setNewOrderCustomers,
    editProductOpen,
    setEditProductOpen,
    selectedProductInfo,
    customerInputs,
    setCustomerInputs,
    discountReason,
    setDiscountReason,
    discountAccordionOpen,
    setDiscountAccordionOpen,
    errorMessage,
    allow_discount,
    customers_per_order_enabled,
    wholesale_enabled,
    external_source_types_enabled,
    cartQuantitiesValid,
    discount,
    handleCustomerInputChange,
    dispatch,
    cloud_cash_register_enabled,
    manual_cloud_cash_register_enabled,
    available_external_sources,
    addOrderLoading,
    isBulkUpdateLoading,
    addCustomerLoading,
  };
};

export default useCart;
