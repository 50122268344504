import React, { useMemo } from "react";
import Chart from "chart.js";
import { Col, Row } from "reactstrap";
import { CardChart } from "components/CardChart";

import { chartOptions, parseOptions } from "variables/charts.js";
import useDashboard from "./useDashboard";
import DashboardLayout from "templates/DashboardLayout";
import { OrdersTable } from "components/OrdersTable/OrdersTable";
import { MetricsSection } from "components/MetricsSection/MetricsSection";
import { DEFAULT_CHARTS_FORM_DATA } from "utilities/enums/Analytics";
import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";
import { SalesOriginChart } from "components/SalesOriginChart";
import { KeyMetricsSection } from "components/MetricsSection/KeyMetricsSection";
import UserSalesTable from "components/Dashboard/UserSalesTable";
import { getAvailableExternalSources } from "utilities/utils";

function Dashboard() {
  const { t } = useTranslation();

  const { payment_method_enabled, available_external_sources } = useSelector((state) => state.settings);
  const external_sources_metrics = useMemo(() => {
    return getAvailableExternalSources(available_external_sources).map((source) => `external_source_${source.value}`);
  }, [available_external_sources]);
  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }
  const { salesOriginChartData, salesChartData, ordersChartData, isLoading, isError } = useDashboard({
    reportPeriod: "day",
  });

  return (
    <DashboardLayout isError={isError} isLoading={isLoading}>
      <MetricsSection
        reportPeriod="day"
        metricsToRender={["total_orders", "payed_orders", "total_sales", "total_cancelled_revenue"]}
      />
      <Row>
        <Col lg="8" xl="8">
          {salesChartData && (
            <CardChart
              key_name={salesChartData.key_name}
              chartData={salesChartData.chartData.data}
              chartNav={salesChartData.chartData.chartNav}
              chartType={salesChartData.chartData.chartType || "line"}
              chartFormData={salesChartData.formData !== null ? salesChartData.formData : DEFAULT_CHARTS_FORM_DATA}
              chartId={salesChartData.id}
              prevSelectedChart={salesChartData.selectedChart}
            />
          )}
        </Col>
        <Col lg="4" xl="4">
          {ordersChartData && (
            <CardChart
              key_name={ordersChartData.key_name}
              chartData={ordersChartData.chartData.data}
              chartNav={ordersChartData.chartData.chartNav}
              chartType={ordersChartData.chartData.chartType || "bar"}
              chartFormData={ordersChartData.formData !== null ? ordersChartData.formData : DEFAULT_CHARTS_FORM_DATA}
              chartId={ordersChartData.id}
              prevSelectedChart={ordersChartData.selectedChart}
            />
          )}
        </Col>
        {payment_method_enabled ? (
          <Col md="6">
            {salesOriginChartData && (
              <SalesOriginChart
                key_name={salesOriginChartData.key_name}
                chartData={salesOriginChartData.chartData.data}
                chartNav={salesOriginChartData.chartData.chartNav}
                chartType={salesOriginChartData.chartData.chartType}
                chartFormData={salesOriginChartData?.formData || {}}
                report_period={salesOriginChartData?.report_period || "day"}
              />
            )}
          </Col>
        ) : null}
        <UserSalesTable reportPeriod="day" />
        <Col md="6">
          <KeyMetricsSection
            reportPeriod="day"
            metricsToRender={[
              "total_gifted_orders",
              "total_pending_orders",
              "total_pending_revenue",
              "total_customers",
              "average_revenue_per_customer",
              "total_discount",
              "total_invoiced_revenue",
              "canceled_orders",
              "total_tip",
              ...external_sources_metrics,
            ]}
            defaultSize="6"
          />
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <OrdersTable title={t("Orders")} subtitle={t("live_orders_table")} pageSize={7} />
        </Col>
      </Row>
    </DashboardLayout>
  );
}

export default Dashboard;
