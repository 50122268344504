export const KeynameToIcon = Object.freeze({
  total_orders: "fa fa-shopping-cart",
  total_sales: "fas fa-euro-sign",
  payed_orders: "fas fa-receipt",
  canceled_orders: "fas fa-ban",
  total_net_sales: "fas fa-chart-line",
  total_cancelled_revenue: "fas fa-ban",
  total_cancelled_orders: "fas fa-ban",
  total_customers: "fa fa-users",
  average_revenue_per_customer: "fa fa-user-check",
  total_gifted_orders: "fa fa-gift",
  total_pending_orders: "fa fa-clock",
  total_pending_revenue: "fa fa-clock",
  total_discount: "fa fa-gift",
  total_invoiced_revenue: "fas fa-chart-line",
  total_tip: "fa fa-coins",
  external_source_wolt: "fa fa-coins",
  external_source_efood: "fa fa-coins",
  external_source_box: "fa fa-coins",
  external_source_regroup: "fa fa-coins",
});

export const KeynameToIconColor = Object.freeze({
  total_orders: "bg-gradient-blue",
  total_sales: "bg-gradient-blue",
  payed_orders: "bg-gradient-blue",
  canceled_orders: "bg-gradient-blue",
  total_net_sales: "bg-gradient-blue",
  total_cancelled_orders: "bg-gradient-blue",
  total_cancelled_revenue: "bg-gradient-blue",
  total_customers: "bg-gradient-blue",
  average_revenue_per_customer: "bg-gradient-blue",
  total_gifted_orders: "bg-gradient-blue",
  total_pending_orders: "bg-gradient-blue",
  total_pending_revenue: "bg-gradient-blue",
  total_discount: "bg-gradient-blue",
  total_invoiced_revenue: "bg-gradient-blue",
  total_tip: "bg-gradient-blue",
  external_source_wolt: "bg-gradient-blue",
  external_source_efood: "bg-gradient-blue",
  external_source_box: "bg-gradient-blue",
  external_source_regroup: "bg-gradient-blue",
});

export const ReportPeriodFooter = Object.freeze({
  day: "since_yesterday",
  week: "since last week",
  month: "since last month",
  year: "since last year",
});

export const KeynameToTitle = Object.freeze({
  total_orders: "Orders",
  total_sales: "Sales",
  payed_orders: "Payed_plural",
  canceled_orders: "Cancelled_plural",
  trending: "Trending",
  top_ten_products: "Top_10_Products",
  sales_overview: "Sales",
  orders_overview: "Orders",
  users_stats: "User_Sales",
  product_stats: "Product_Sales",
  total_net_sales: "Net_Sales",
  total_cancelled_revenue: "Cancelled_Revenue_plural",
  total_cancelled_orders: "Cancelled_plural",
  sales_origin_overview: "Sales_Origin",
  total_customers: "Customers",
  average_revenue_per_customer: "Average_Revenue_Per_Customer",
  total_gifted_orders: "Gift",
  total_pending_orders: "Pending_Orders",
  total_pending_revenue: "Pending_Revenue",
  total_discount: "Total_Discount",
  total_invoiced_revenue: "Invoiced_Revenue",
  total_tip: "Total_Tip",
  external_source_wolt: "Wolt",
  external_source_efood: "Efood",
  external_source_box: "Box",
  external_source_regroup: "Regroup",
});

export const KeynameToSubtitle = Object.freeze({
  trending: "Trending_Products_Descr",
  top_ten_products: "Top_10_Products_Descr",
  sales_overview: "Overview",
  orders_overview: "Overview",
  users_stats: "User_Sales_Descr",
  product_stats: "Product_Sales_Descr",
  sales_origin_overview: "Sales_Origin_Descr",
});

export const DEFAULT_CHARTS_FORM_DATA = Object.freeze({
  formData: [
    {
      Chart: {
        title: "Week",
        groupby: "week",
      },
    },
    {
      Chart: {
        title: "Month",
        groupby: "month",
      },
    },
    {
      Chart: {
        title: "Year",
        groupby: "year",
      },
    },
  ],
});
