import React, { useEffect, useMemo, useReducer } from "react";
import Chart from "chart.js";
import { startOfMonth, format } from "date-fns";
import { DayPicker } from "react-day-picker";
import { Views } from "utilities/enums/Views";
import { Label, FormGroup, Input, Button, Col, Container, PopoverBody, Row, UncontrolledPopover } from "reactstrap";
import {
  useGetAnalyticsSalesQuery,
  useGetAnalyticsOrdersQuery,
  useGetAnalyticsUserSalesQuery,
  useGetAnalyticsProdcutSalesQuery,
  useUpdateAnalyticsMutation,
  apiAnalytics,
} from "api/analyticsSlice";
import { CardTable } from "components/CardTable";
import { chartOptions, parseOptions } from "variables/charts";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { DEFAULT_CHARTS_FORM_DATA } from "utilities/enums/Analytics";
import PuffLoader from "react-spinners/PuffLoader";
import { ProductCardChart } from "components/ProductCardChart";
import { SalesCardChart } from "components/SalesCardChart";
import { OrdersCardChart } from "components/OrdersCardChart";
import "react-day-picker/dist/style.css";
import { useDispatch } from "react-redux";
import { TagTypes } from "utilities/enums/TagTypes";
import { useGetMetricsQuery } from "api/analyticsSlice";
import { DEFAULT_ANALYTICS_METRICS } from "utilities/analytics/charts";
import { CardMetric } from "components/CardMetric";
import { RingSpinnerOverlay } from "react-spinner-overlay";
import { el, enUS } from "date-fns/locale";
import { useSelector } from "react-redux";
import { formatDate, formatDateCustomFormat } from "utilities/utils";
import { useTranslation } from "react-i18next";
import { useGetSalesOriginQuery } from "api/analyticsSlice";
import { SalesOriginChart } from "components/SalesOriginChart";
import { KeyMetricsSection } from "components/MetricsSection/KeyMetricsSection";
import { SortableTable } from "components/Tables/SortableTable";
import { triggerNotification } from "utilities/utils";
import NotificationAlert from "react-notification-alert";
import { getAvailableExternalSources } from "utilities/utils";

function Analytics() {
  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }
  const { t } = useTranslation();
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const dispatch = useDispatch();
  const { locale } = useSelector((state) => state.locale);
  const [updateAnalytics, { isLoading: updateAnalyticsLoading, isError: updateAnalyticsError }] =
    useUpdateAnalyticsMutation();
  const { payment_method_enabled, use_analytic_functions_disabled, available_external_sources } = useSelector(
    (state) => state.settings
  );

  const external_sources_metrics = useMemo(() => {
    return getAvailableExternalSources(available_external_sources).map((source) => `external_source_${source.value}`);
  }, [available_external_sources]);

  const notificationAlertRef = React.useRef(null);

  const getDaysDifference = (start, end) => {
    const diffTime = Math.abs(end - start);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  };

  const [isValidPeriod, setIsValidPeriod] = React.useState(true);
  const currentMonth = startOfMonth(new Date());
  const defaultSelected = {
    from: currentMonth,
    to: new Date(),
    fromTime: "10:00",
    toTime: "10:00",
  };
  const [range, setRange] = React.useState(defaultSelected);
  const setRangeWithValidation = (newRange) => {
    setRange(newRange);
  };

  useEffect(() => {
    if (range) {
      const daysDifference = getDaysDifference(range.from, range.to);
      const limitDays = use_analytic_functions_disabled ? 15 : 365;
      setIsValidPeriod(daysDifference <= limitDays);
    }
  }, [range, use_analytic_functions_disabled]);

  let dateLabel = <>Please pick date Range.</>;
  if (range?.from) {
    let fromTimeLabel = range?.fromTime ? range?.fromTime : "";
    let toTimeLabel = range?.toTime ? range?.toTime : "";
    if (!range.to) {
      dateLabel = <>{fromTimeLabel + " " + formatDate(range.from, locale) + " " + toTimeLabel}</>;
    } else if (range.to) {
      dateLabel = (
        <>
          {formatDate(range.from, locale) + " " + fromTimeLabel + " "}–
          {" " + formatDate(range.to, locale) + " " + toTimeLabel}
        </>
      );
    }
  }

  const {
    data: metrics,
    isLoading: metricsLoading,
    isError: metricsError,
  } = useGetMetricsQuery({ reportPeriod: "custom" });

  const {
    data: salesChartData,
    isLoading: salesChartLoading,
    isError: salesChartError,
  } = useGetAnalyticsSalesQuery({ reportPeriod: "custom" });
  const {
    data: salesOriginChartData,
    isLoading: salesOriginChartLoading,
    isError: salesOriginChartError,
  } = useGetSalesOriginQuery({ reportPeriod: "custom" });
  const {
    data: ordersChartData,
    isLoading: ordersChartLoading,
    isError: ordersChartError,
  } = useGetAnalyticsOrdersQuery({ reportPeriod: "custom" });

  const {
    data: productSalesData,
    isLoading: productSalesLoading,
    isError: productSalesError,
  } = useGetAnalyticsProdcutSalesQuery({ reportPeriod: "custom" });

  const {
    data: userSalesData,
    isLoading: userSalesLoading,
    isError: userSalesError,
  } = useGetAnalyticsUserSalesQuery({ reportPeriod: "custom" });

  useEffect(() => {
    if (salesChartData && salesChartData?.formData?.start_date && salesChartData?.formData?.end_date) {
      setRange({
        from: new Date(salesChartData?.formData?.start_date),
        to: new Date(salesChartData?.formData?.end_date),
        fromTime: salesChartData?.formData?.start_time || "10:00",
        toTime: salesChartData?.formData?.end_time || "10:00",
      });
    }
  }, [salesChartData]);

  const isLoading =
    salesChartLoading || ordersChartLoading || userSalesLoading || salesOriginChartLoading || productSalesLoading;

  if (isLoading) {
    return (
      <div className="w-100 d-flex justify-content-center">
        <PuffLoader loading={isLoading} color="#d9d9d9" size={50} aria-label="Loading Spinner" data-testid="loader" />
      </div>
    );
  }
  const isError = salesChartError || ordersChartError || userSalesError || salesOriginChartError || productSalesError;

  return (
    <>
      {updateAnalyticsLoading && <RingSpinnerOverlay loading={updateAnalyticsLoading} color="#11cdef" />}

      <SimpleHeader name="" parentName={Views.ANALYTICS} isLoading={isLoading} isError={isError} />
      <Container className={`mt-${isLoading ? "6" : "-6"}`} fluid>
        <Row className="mt-3 mb-4 mb-md-5 ">
          <Col className="mb-1 mt-1 mb-xl-0" xl="12">
            <Row>
              <Col md="6"></Col>
              <Col md="6" className="mt-4 mt-lg-0">
                <div className="d-flex justify-content-end">
                  <Button
                    className="btn-icon ml-0 btn btn-primary d-flex justify-content-center align-items-center pl-3 pr-3"
                    color="secondary"
                    size="sm"
                    disabled={!range?.from || !range?.to || updateAnalyticsLoading}
                    onClick={async () => {
                      setRange(range);
                      if (range?.from && range?.to) {
                        updateAnalytics({
                          formData: {
                            start_date: format(range.from, "yyyy-MM-dd"),
                            end_date: format(range.to, "yyyy-MM-dd"),
                            start_time: range?.fromTime,
                            end_time: range?.toTime,
                          },
                        }).then(({ data }) => {
                          if (!updateAnalyticsLoading && updateAnalyticsError) {
                            triggerNotification(notificationAlertRef, "warning", "", t("Analytics_Try_again_later"));
                            return;
                          }
                          if (!updateAnalyticsLoading && !updateAnalyticsError) {
                            if (data?.error && data?.message) {
                              triggerNotification(
                                notificationAlertRef,
                                data?.level || "danger",
                                t(data?.title || ""),
                                t(data?.message)
                              );
                              return;
                            }
                            dispatch(apiAnalytics.util.invalidateTags([TagTypes.Analytics]));
                            forceUpdate();
                          }
                        });
                      }
                    }}
                  >
                    <span className="btn-inner--icon text-sm">
                      <i className="fas fa-redo" />
                    </span>
                  </Button>
                  <Button id="Popover1" type="button" size="sm" className="p-2">
                    {dateLabel}
                  </Button>
                  <UncontrolledPopover
                    target="Popover1"
                    className="popover-secondary calendar-popover"
                    trigger="legacy"
                  >
                    <PopoverBody className="pb-3">
                      <DayPicker
                        id="dateRangePicker"
                        className="date-range-picker"
                        mode="range"
                        showOutsideDays={true}
                        defaultMonth={currentMonth}
                        selected={range}
                        footer={<></>}
                        onSelect={(e) => {
                          setRangeWithValidation({
                            ...range,
                            ...e,
                          });
                        }}
                        locale={locale === "el" ? el : enUS}
                      />
                      <FormGroup className="row mb-2 mb-md-4">
                        <Label className="form-control-label" htmlFor="start-time-input" md="5">
                          {range?.from ? formatDateCustomFormat(range.from, locale, "D MMM YYYY") : t("From")}
                        </Label>
                        <Col md="7">
                          <Input
                            defaultValue={range?.fromTime}
                            id="start-time-input"
                            type="time"
                            onChange={(e) => {
                              setRange({ ...range, fromTime: e.target.value });
                            }}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="form-control-label" htmlFor="end-time-input" md="5">
                          {range?.to ? formatDateCustomFormat(range.to, locale, "D MMM YYYY") : t("To")}
                        </Label>
                        <Col md="7">
                          <Input
                            defaultValue={range?.toTime}
                            id="end-time-input"
                            type="time"
                            onChange={(e) => {
                              setRange({ ...range, toTime: e.target.value });
                            }}
                          />
                        </Col>
                      </FormGroup>
                      {!isValidPeriod && (
                        <div className="text-danger mt-2">
                          {use_analytic_functions_disabled ? t("Select_15_day_period") : t("Select_365_day_period")}
                        </div>
                      )}
                      <Button
                        disabled={true}
                        className="mt-2"
                        color="secondary"
                        size="sm"
                        onClick={() => setRange(defaultSelected)}
                      >
                        {t("Last_30_days")}
                      </Button>
                      <Button
                        className="mt-2"
                        color="primary"
                        size="sm"
                        disabled={!range?.from || !range?.to || !isValidPeriod}
                        onClick={async () => {
                          setRange(range);
                          document.getElementById("Popover1").click();
                          if (range?.from && range?.to) {
                            updateAnalytics({
                              formData: {
                                start_date: format(range.from, "yyyy-MM-dd"),
                                end_date: format(range.to, "yyyy-MM-dd"),
                                start_time: range?.fromTime,
                                end_time: range?.toTime,
                              },
                            }).then(({ data }) => {
                              if (!updateAnalyticsLoading && updateAnalyticsError) {
                                triggerNotification(
                                  notificationAlertRef,
                                  "warning",
                                  "",
                                  t("Analytics_Try_again_later")
                                );
                                return;
                              }
                              if (!updateAnalyticsLoading && !updateAnalyticsError) {
                                if (data?.error && data?.message) {
                                  triggerNotification(
                                    notificationAlertRef,
                                    data?.level || "danger",
                                    t(data?.title || ""),
                                    t(data?.message)
                                  );
                                  return;
                                }
                                dispatch(apiAnalytics.util.invalidateTags([TagTypes.Analytics]));
                                forceUpdate();
                              }
                            });
                          }
                        }}
                      >
                        {t("Save")}
                      </Button>
                    </PopoverBody>
                  </UncontrolledPopover>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="row-grid">
          {!metricsLoading &&
            !metricsError &&
            (Object.keys(metrics).length === 0
              ? DEFAULT_ANALYTICS_METRICS &&
                ["total_sales", "total_net_sales", "payed_orders", "total_cancelled_revenue"].map((metric) => (
                  <Col key={metric} xs="12" md="6" lg="3" className="mt-0">
                    <CardMetric
                      {...DEFAULT_ANALYTICS_METRICS[metric]?.chartData}
                      key_name={metric}
                      report_period={DEFAULT_ANALYTICS_METRICS[metric]?.report_period}
                    />
                  </Col>
                ))
              : ["total_sales", "total_net_sales", "payed_orders", "total_cancelled_revenue"].map((metric) => (
                  <Col key={metrics[metric]?.id} xs="12" md="6" lg="3" className="mt-0">
                    <CardMetric
                      {...metrics[metric]?.chartData}
                      key_name={metric}
                      report_period={metrics[metric]?.report_period}
                    />
                  </Col>
                )))}
        </Row>
        <Row>
          <Col md="6">
            {salesChartData && (
              <SalesCardChart
                key_name={salesChartData.key_name}
                chartData={salesChartData.chartData.data}
                chartNav={salesChartData.chartData.chartNav}
                chartType={salesChartData.chartData.chartType}
                chartFormData={salesChartData?.formData || {}}
                report_period={salesChartData?.report_period || "day"}
              />
            )}
          </Col>

          {payment_method_enabled ? (
            <Col md="6">
              {salesOriginChartData && (
                <SalesOriginChart
                  key_name={salesOriginChartData.key_name}
                  chartData={salesOriginChartData.chartData.data}
                  chartNav={salesOriginChartData.chartData.chartNav}
                  chartType={salesOriginChartData.chartData.chartType}
                  chartFormData={salesOriginChartData?.formData || {}}
                  report_period={salesOriginChartData?.report_period || "day"}
                />
              )}
            </Col>
          ) : null}

          <Col md="6">
            <KeyMetricsSection
              reportPeriod="custom"
              metricsToRender={[
                "total_gifted_orders",
                "total_customers",
                "average_revenue_per_customer",
                "total_discount",
                "total_invoiced_revenue",
                "total_cancelled_orders",
                "total_tip",
                ...external_sources_metrics,
              ]}
              defaultSize="6"
            />
          </Col>
          <Col md="6">
            {ordersChartData && (
              <OrdersCardChart
                key_name={ordersChartData.key_name}
                chartData={ordersChartData.chartData.data}
                chartNav={ordersChartData.chartData.chartNav}
                chartType={ordersChartData.chartData.chartType}
                chartFormData={ordersChartData?.formData || {}}
                report_period={ordersChartData?.report_period || "day"}
              />
            )}
          </Col>
          <Col md="6">
            {productSalesData && (
              <ProductCardChart
                key_name={productSalesData.key_name}
                chartData={productSalesData.chartData.data}
                chartNav={productSalesData.chartData.chartNav}
                chartType={productSalesData.chartData.chartType}
                chartFormData={productSalesData?.formData || {}}
                report_period={productSalesData?.report_period || "day"}
              />
            )}
          </Col>
          <Col md="6">
            {userSalesData && (
              <CardTable
                key_name={userSalesData.key_name}
                chartData={userSalesData.chartData.data}
                chartNav={userSalesData.chartData.chartNav}
                chartType={userSalesData.chartData.chartType}
                chartFormData={userSalesData?.formData || DEFAULT_CHARTS_FORM_DATA}
                report_period={userSalesData?.report_period || "day"}
              />
            )}
          </Col>
          <Col md="6">
            <SortableTable keyname="products_table" title={t("Sales_per_product")} />
          </Col>
          <Col md="6">
            <SortableTable keyname="categories_table" title={t("Sales_per_category")} />
          </Col>
        </Row>
        <div className="rna-wrapper">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
      </Container>
    </>
  );
}

export default Analytics;
