import { useGetAnalyticsMetricsQuery } from "api/analyticsSlice";
import { useSelector } from "react-redux";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { subscribeToChartsChangeWS } from "config/websocket";
import { useEffect, useReducer, useState } from "react";
import { useDispatch } from "react-redux";
import { TagTypes } from "utilities/enums/TagTypes";
import { apiAnalytics } from "api/analyticsSlice";
import { DEFAULT_METRICS } from "utilities/analytics/charts";
import PuffLoader from "react-spinners/PuffLoader";
import { t } from "i18next";
import { CardMetric } from "components/CardMetric";
import { selectCurrentOrganisation } from "auth/authSlice";
import { getAvailableExternalSources, shouldRenderMetric } from "utilities/utils";

export const KeyMetricsSection = ({ reportPeriod, metricsToRender, defaultSize = "3" }) => {
  const dispatch = useDispatch();

  const {
    data: metrics,
    isLoading,
    isError,
  } = useGetAnalyticsMetricsQuery({
    reportPeriod: reportPeriod,
  });

  const settings = useSelector((state) => ({
    customers_per_order_enabled: state.settings.customers_per_order_enabled,
    discounts_enabled: state.settings.discounts_enabled,
    manual_cloud_cash_register_enabled: state.settings.manual_cloud_cash_register_enabled,
    cloud_cash_register_enabled: state.settings.cloud_cash_register_enabled,
    pos_integration_enabled: state.settings.pos_integration_enabled,
    external_source_types_enabled: state.settings.external_source_types_enabled,
    external_sources_metrics: getAvailableExternalSources(state.settings.available_external_sources).map(
      (source) => `external_source_${source.value}`
    ),
  }));

  const organisationId = useSelector(selectCurrentOrganisation);

  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  function refreshComponent() {
    dispatch(apiAnalytics.util.invalidateTags([TagTypes.Analytics]));
    forceUpdate();
  }
  useEffect(() => {
    subscribeToChartsChangeWS(organisationId, refreshComponent);
  }, [organisationId]);

  if (isLoading) {
    return <PuffLoader loading={true} color="#d9d9d9" size={50} aria-label="Loading Spinner" data-testid="loader" />;
  }

  if (isError) {
    return (
      <Row className="row-grid">
        <Col>Error</Col>
      </Row>
    );
  }

  const hasMetrics = metrics && Object.keys(metrics).length > 0;

  return (
    <Card>
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <Col xs="12">
            <h6 className="text-light text-uppercase ls-1 mb-1">{t("Key_metrics_subtitle")}</h6>
            <h5 className="h3 mb-0">{t("Key_metrics")}</h5>
          </Col>
        </Row>
      </CardHeader>
      <CardBody className="d-flex flex-wrap p-2 pt-3">
        {metricsToRender.map((metric) => {
          const metricData = hasMetrics ? metrics[metric] || DEFAULT_METRICS[metric] : DEFAULT_METRICS[metric];
          return (
            shouldRenderMetric(metricData?.key_name || metric, settings) && (
              <Col
                data-testid={metricData?.key_name}
                key={metricData?.id}
                xs="12"
                lg={defaultSize}
                className="mt-0 pl-2 pr-2"
              >
                <CardMetric {...metricData?.chartData} key_name={metric} report_period={reportPeriod} />
              </Col>
            )
          );
        })}
      </CardBody>
    </Card>
  );
};
