import i18n from "i18n/i18n";
export const DEFAULT_CHARTS_SCHEMA = {
  type: "object",
  title: "",
  description: "",
  properties: {
    Chart: {
      type: "object",
      title: "",
      description: "",
      properties: {
        title: {
          title: i18n.t("Chart_name"),
          type: "string",
        },
        start_date: {
          title: i18n.t("From"),
          type: "string",
          format: "date",
        },
        end_date: {
          title: i18n.t("To"),
          type: "string",
          format: "date",
        },
        groupby: {
          title: i18n.t("Group_By"),
          type: "string",
          oneOf: [
            {
              const: "day",
              title: i18n.t("Day"),
            },
            {
              const: "week",
              title: i18n.t("Week"),
            },
            {
              const: "month",
              title: i18n.t("Month"),
            },
            {
              const: "year",
              title: i18n.t("Year"),
            },
          ],
        },
      },
    },
  },
};

export const DEFAULT_UI_SCHEMA = {
  title: {
    "ui:widget": "text",
  },
  start_date: {
    "ui:widget": "date",
  },
  end_date: {
    "ui:widget": "date",
  },
  groupby: {
    "ui:widget": "select",
  },
  "ui:options": {
    title: "",
    description: "",
    classNames: "form-group",
    submitButtonOptions: {
      props: {
        disabled: false,
        className: "btn btn-info",
      },
      norender: true,
      submitText: "Submit",
    },
  },
};

export const DEFAULT_METRICS = {
  total_sales: {
    id: 1,
    chartData: {
      value: 0,
    },
    type: "metric",
    report_period: "custom",
    rank: 1,
  },
  total_net_sales: {
    id: 2,
    chartData: {
      value: 0,
    },
    type: "metric",
    report_period: "custom",
    rank: 2,
  },
  total_orders: {
    id: 3,
    chartData: {
      value: 0,
    },
    type: "metric",
    report_period: "custom",
    rank: 3,
  },
  total_cancelled_revenue: {
    id: 4,
    chartData: {
      value: 0,
    },
    type: "metric",
    report_period: "custom",
    rank: 4,
  },
  total_cancelled_orders: {
    id: 9,
    chartData: {
      value: 0,
    },
    type: "metric",
    report_period: "custom",
    rank: 4,
  },
  total_customers: {
    id: 5,
    chartData: {
      value: 0,
    },
    type: "metric",
    report_period: "custom",
    rank: 5,
  },
  average_revenue_per_customer: {
    id: 6,
    chartData: {
      value: 0,
    },
    type: "metric",
    report_period: "custom",
    rank: 6,
  },
  total_gifted_orders: {
    id: 7,
    chartData: {
      value: 0,
    },
    type: "metric",
    report_period: "custom",
    rank: 7,
  },
  total_pending_revenue: {
    id: 8,
    chartData: {
      value: 0,
    },
    type: "metric",
    report_period: "custom",
    rank: 8,
  },
  canceled_orders: {
    id: 9,
    chartData: {
      value: 0,
    },
    type: "metric",
    report_period: "custom",
    rank: 9,
  },
  total_pending_orders: {
    id: 10,
    chartData: {
      value: 0,
    },
    type: "metric",
    report_period: "custom",
    rank: 10,
  },
  payed_orders: {
    id: 11,
    chartData: {
      value: 0,
    },
    type: "metric",
    report_period: "custom",
    rank: 11,
  },
  total_discount: {
    id: 12,
    chartData: {
      value: 0,
    },
    type: "metric",
    report_period: "custom",
    rank: 12,
  },
  total_tip: {
    id: 13,
    chartData: {
      value: 0,
    },
    type: "metric",
    report_period: "custom",
    rank: 13,
  },
  total_invoiced_revenue: {
    id: 14,
    chartData: {
      value: 0,
    },
    type: "metric",
    report_period: "custom",
    rank: 14,
  },
  external_source_efood: {
    id: 15,
    chartData: {
      value: 0,
    },
    type: "metric",
    report_period: "custom",
    rank: 15,
  },
  external_source_wolt: {
    id: 16,
    chartData: {
      value: 0,
    },
    type: "metric",
    report_period: "custom",
    rank: 16,
  },
  external_source_box: {
    id: 17,
    chartData: {
      value: 0,
    },
    type: "metric",
    report_period: "custom",
    rank: 17,
  },
  external_source_regroup: {
    id: 18,
    chartData: {
      value: 0,
    },
    type: "metric",
    report_period: "custom",
    rank: 18,
  },
};

export const DEFAULT_ANALYTICS_METRICS = {
  total_sales: {
    id: 1,
    chartData: {
      value: 0,
    },
    type: "metric",
    report_period: "custom",
    rank: 1,
    key_name: "total_sales",
  },
  total_net_sales: {
    id: 2,
    chartData: {
      value: 0,
    },
    type: "metric",
    report_period: "custom",
    rank: 2,
    key_name: "total_net_sales",
  },
  payed_orders: {
    id: 11,
    chartData: {
      value: 0,
    },
    type: "metric",
    report_period: "custom",
    rank: 11,
  },
  cancelled_orders: {
    id: 4,
    chartData: {
      value: 0,
    },
    type: "metric",
    report_period: "custom",
    rank: 3,
    key_name: "cancelled_orders",
  },
  total_cancelled_orders: {
    id: 5,
    chartData: {
      value: 0,
    },
    type: "metric",
    report_period: "custom",
    rank: 4,
    key_name: "total_cancelled_orders",
  },
  total_discount: {
    id: 6,
    chartData: {
      value: 0,
    },
    type: "metric",
    report_period: "custom",
    rank: 6,
  },
  total_customers: {
    id: 7,
    chartData: {
      value: 0,
    },
    type: "metric",
    report_period: "custom",
    rank: 7,
  },
  total_tip: {
    id: 8,
    chartData: {
      value: 0,
    },
    type: "metric",
    report_period: "custom",
    rank: 8,
  },
  total_invoiced_revenue: {
    id: 9,
    chartData: {
      value: 0,
    },
    type: "metric",
    report_period: "custom",
    rank: 9,
  },
};
