import { isEqual } from "lodash";
import { v4 as uuidv4 } from "uuid";
import moment from "moment/moment";
import ROUTES_OBJ from "utilities/enums/Routes";
import "moment/locale/el";
import "moment/locale/en-gb";
import { PUBLIC_ROUTES, USER_ROLES } from "./constants";
import * as Sentry from "@sentry/browser";

const CURRENCY_FORMATTER = new Intl.NumberFormat("el-GR", {
  currency: "EUR",
  style: "currency",
});

const EMAIL_REG_VALID =
  /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function generateUUID(user, organisationId) {
  return `${uuidv4()}-${user}-${organisationId}`;
}

export function validateEmail(email) {
  return EMAIL_REG_VALID.test(email);
}

export function formatCurrency(number) {
  return CURRENCY_FORMATTER.format(number);
}

export function formatDateTime(date, locale) {
  if (!date) return "";

  date = new Date(date);

  if (locale === "en") locale = "en-gb";
  moment.locale(locale);
  return moment(date).format("D MMM YYYY HH:mm:ss");
}

export function formatDate(date, locale) {
  if (!date) return "";

  date = new Date(date);

  if (locale === "en") locale = "en-gb";
  moment.locale(locale);
  return moment(date).format("LL");
}

export function formatDateCustomFormat(date, locale, format) {
  if (!date) return "";

  date = new Date(date);

  if (locale === "en") locale = "en-gb";
  moment.locale(locale);
  return moment(date).format(format);
}

export function mergeToCommaSepString(array) {
  return array?.join(", ");
}

export function safeToString(object) {
  return object + "";
}

export function capitalizeFirstLetter(string) {
  return string && string.charAt(0).toUpperCase() + string.slice(1);
}

export function getTotalProductPrice(product) {
  return (
    Math.round(
      (product.variant.reduce((acc, variant) => {
        return acc + variant.variant_option.data.attributes.price;
      }, 0) *
        product.quantity +
        product.product.data.attributes.price * product.quantity +
        Number.EPSILON) *
        100
    ) / 100
  );
}

export function logError(error, extraData = null) {
  Sentry.captureException(error, { contexts: { extraData } });
  console.log(error);
}

export function cartItemsEqual(item1, item2) {
  return item1.id === item2.id && isEqual(item1.variants, item2.variants) && item1.notes === item2.notes;
}

export function orderItemsEqual(item1, item2) {
  return item1.product?.data?.id === item2.product?.data?.id && isEqual(item1.variant, item2.variant);
}

export function getItemFromPendingOrders(pendingOrders) {
  let items = [];
  pendingOrders?.data.forEach((order) => {
    order.orderDetails.forEach((orderItem) => {
      let itemFound = false;
      items = items.map((item) => {
        // if the item of the pending order is already found in another order
        if (orderItemsEqual(item, orderItem)) {
          itemFound = true;
          return {
            ...item,
            quantity: item.quantity + orderItem.quantity,
          };
        } else {
          return item;
        }
      });
      if (!itemFound) {
        items = [...items, orderItem];
      }
    });
  });

  return items;
}

export function stripHTML(string) {
  return string ? string.replace(/(<([^>]+)>)/gi, "") : null;
}

export function getDefaultRules() {
  const roles = USER_ROLES;
  const public_routes = PUBLIC_ROUTES;
  const userrules = roles.map((role) => {
    return {
      name: role, // role name ex admin or manager or staff
      rules: Object.entries(ROUTES_OBJ) // get routes path
        .map((route) => `Route:${route[1].path}`) // faltmap and add Route: to each route
        .filter((rule_name) => !public_routes.includes(rule_name)) // remove public routes
        .map((rule_name) => {
          // return object with name and value
          return {
            name: rule_name,
            value: role === "admin" ? true : false,
          };
        }),
    };
  }); // user rules for each role by default all have false except admin
  return userrules;
}

/**
 * Products have a different structure inside an "order" entity and different structure inside the "cart"
 * This function's purspose is to convert a product from the "order" structure to the "cart" one
 */
export function convertOrderProductToCart(orderProduct) {
  let convertedProduct = null;
  if (orderProduct) {
    convertedProduct = {
      id: orderProduct.id,
      quantity: orderProduct.quantity,
      variants: orderProduct.variant.map((variant) => {
        return {
          optionId: variant.variant_option.data.id?.toString(),
          optionLabel: variant.variant_option.data.attributes.label,
          optionPrice: variant.variant_option.data.attributes.price,
          quantity: variant.quantity,
          variantId: variant.variant.data?.id,
          variantLabel: variant.variant.data?.attributes?.label,
        };
      }),
      notes: orderProduct.notes,
      vat_category: orderProduct.product.data.attributes.vat_category,
      name: orderProduct.name,
    };
  }
  return convertedProduct;
}

export function convertCartProductToOrder(cartProduct) {
  let convertedProduct = null;
  if (cartProduct) {
    convertedProduct = {
      quantity: cartProduct.quantity,
      notes: cartProduct.notes,
      variant: cartProduct.variants.map((variant) => {
        return {
          id: null,
          quantity: variant.quantity,
          variant: {
            data: {
              id: variant.variantId,
              attributes: {
                label: variant.variantLabel,
              },
            },
          },
          variant_option: {
            data: {
              id: parseInt(variant.optionId),
              attributes: {
                label: variant.optionLabel,
                price: variant.optionPrice,
              },
            },
          },
        };
      }),
    };
  }

  return convertedProduct;
}
// Accepts an array with objects that each object has a "rank" field that can be either int or null
// useAttributes is a boolean that if true, the rank field is inside the "attributes" field of the object
export function sortByRankAsc(data, useAttributes = false) {
  if (!data || data.length === 0) return;

  return data.sort((a, b) => {
    const rankA = useAttributes ? a.attributes.rank : a.rank;
    const rankB = useAttributes ? b.attributes.rank : b.rank;

    if (rankA === null && rankB === null) {
      return 0;
    } else if (rankA === null) {
      return 1;
    } else if (rankB === null) {
      return -1;
    } else {
      return rankA - rankB;
    }
  });
}

export function getVatMultiplier(vatCategory) {
  switch (vatCategory) {
    case "vat_13":
      return 1.13;
    default:
      return 1.24;
  }
}

export function getNetRevenue(revenue, vatCategory) {
  return revenue / getVatMultiplier(vatCategory);
}

export function normalizeGreek(string) {
  return string?.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export function reoderArrayOnDrop(inputs, result) {
  const items = Array.from(inputs);
  const [reorderedItem] = items.splice(result.source.index, 1);
  items.splice(result.destination.index, 0, reorderedItem);
  return items;
}

export function triggerNotification(notificationRef, type, title, subtitle) {
  let options = {
    place: "tc",
    message: (
      <div className="alert-text">
        <span className="alert-title" data-notify="title">
          {" "}
          {title}
        </span>
        <span data-notify="message">{subtitle}</span>
      </div>
    ),
    type: type,
    icon: "ni ni-bell-55",
    autoDismiss: 7,
  };
  notificationRef.current.notificationAlert(options);
}

export function urlBase64ToUint8Array(a) {
  const b = "=".repeat((4 - (a.length % 4)) % 4),
    c = (a + b).replace(/\-/g, "+").replace(/_/g, "/"),
    d = window.atob(c),
    f = new Uint8Array(d.length);
  for (let g = 0; g < d.length; ++g) f[g] = d.charCodeAt(g);
  return f;
}

// returns true if versionA is greater than versionB
export const checkVersionGreaterThan = function (versionA, versionB) {
  if (!versionA || !versionB) return false;
  var versionsA = versionA.split(/\./g),
    versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB?.length) {
    var a = Number(versionsA.shift()),
      b = Number(versionsB.shift());
    if (a === b) continue;
    return a > b || isNaN(b);
  }
  return false;
};

export function normalizeTipAmount(tipAmount) {
  if (!tipAmount || tipAmount === "") return "0.00";
  return tipAmount.replace(",", ".").replace(/[^0-9.]/g, "");
}

export function getAvailableExternalSources(available_external_sources) {
  return available_external_sources && available_external_sources.length > 0
    ? available_external_sources
    : [
        {
          label: "Efood",
          value: "efood",
        },
        {
          label: "Wolt",
          value: "wolt",
        },
      ];
}

export function shouldRenderMetric(metric, settings) {
  switch (metric) {
    case "total_customers":
    case "average_revenue_per_customer":
      return settings.customers_per_order_enabled;
    case "total_discount":
      return settings.discounts_enabled;
    case "total_invoiced_revenue":
      return settings.manual_cloud_cash_register_enabled && settings.cloud_cash_register_enabled;
    case "total_tip":
      return settings.pos_integration_enabled;
    case "external_source_efood":
    case "external_source_wolt":
    case "external_source_box":
    case "external_source_regroup":
      return settings.external_source_types_enabled && settings.external_sources_metrics.includes(metric);
    default:
      return true;
  }
}
