import { useGetAnalyticsMetricsQuery } from "api/analyticsSlice";
import { CardStats } from "components/CardStats";
import { Col, Row } from "reactstrap";
import { subscribeToChartsChangeWS } from "config/websocket";
import { useEffect, useReducer } from "react";
import { useDispatch } from "react-redux";
import { TagTypes } from "utilities/enums/TagTypes";
import { apiAnalytics } from "api/analyticsSlice";
import { DEFAULT_METRICS } from "utilities/analytics/charts";
import PuffLoader from "react-spinners/PuffLoader";
import { selectCurrentOrganisation } from "auth/authSlice";
import { useSelector } from "react-redux";

export const MetricsSection = ({ reportPeriod, metricsToRender, defaultSize = "3" }) => {
  const dispatch = useDispatch();
  const organisationId = useSelector(selectCurrentOrganisation);

  const {
    data: metrics,
    isLoading: metricsLoading,
    isError: metricsError,
  } = useGetAnalyticsMetricsQuery({
    reportPeriod: reportPeriod,
  });
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  function refreshComponent() {
    dispatch(apiAnalytics.util.invalidateTags([TagTypes.Analytics]));
    forceUpdate();
  }
  useEffect(() => {
    subscribeToChartsChangeWS(organisationId, refreshComponent);
  }, [organisationId]);
  if (metricsLoading) {
    <PuffLoader loading={metricsLoading} color="#d9d9d9" size={50} aria-label="Loading Spinner" data-testid="loader" />;
  }
  if (metricsError) {
    return (
      <Row className="row-grid">
        <Col>Error</Col>
      </Row>
    );
  }

  const shouldUseDefaultMetrics = !metrics || Object.keys(metrics).length === 0;
  const metricsSource = shouldUseDefaultMetrics ? DEFAULT_METRICS : metrics;

  return (
    <Row className="row-grid mb-2">
      {metricsToRender.map((metric) => (
        <Col key={metric} xs="6" lg={defaultSize} className="mt-0">
          <CardStats {...metricsSource[metric]?.chartData} key_name={metric} report_period={reportPeriod} />
        </Col>
      ))}
    </Row>
  );
};
